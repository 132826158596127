@font-face {
	font-family: InterVariable;
	font-style: normal;
	font-weight: 100 900;
	font-display: swap;
	src: url('/fonts/inter/InterVariable.woff2') format('woff2');
}
@font-face {
	font-family: InterVariable;
	font-style: italic;
	font-weight: 100 900;
	font-display: swap;
	src: url('/fonts/inter/InterVariable-Italic.woff2') format('woff2');
}

/* static fonts */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url('/fonts/inter/Inter-Thin.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url('/fonts/inter/Inter-ThinItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url('/fonts/inter/Inter-ExtraLight.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url('/fonts/inter/Inter-ExtraLightItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('/fonts/inter/Inter-Light.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url('/fonts/inter/Inter-LightItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('/fonts/inter/Inter-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('/fonts/inter/Inter-Italic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('/fonts/inter/Inter-Medium.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url('/fonts/inter/Inter-MediumItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('/fonts/inter/Inter-SemiBold.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url('/fonts/inter/Inter-SemiBoldItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('/fonts/inter/Inter-Bold.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('/fonts/inter/Inter-BoldItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('/fonts/inter/Inter-ExtraBold.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url('/fonts/inter/Inter-ExtraBoldItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('/fonts/inter/Inter-Black.woff2') format('woff2');
}
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url('/fonts/inter/Inter-BlackItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-Thin.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-ThinItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-ExtraLight.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-ExtraLightItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-Light.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-LightItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-Italic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-Medium.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-MediumItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-SemiBold.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-SemiBoldItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-Bold.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-BoldItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-ExtraBold.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-ExtraBoldItalic.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-Black.woff2') format('woff2');
}
@font-face {
	font-family: 'InterDisplay';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url('/fonts/inter/InterDisplay-BlackItalic.woff2') format('woff2');
}

.apexcharts-legend {
	display: flex !important;
	flex-direction: column !important;
	align-items: left !important;
	justify-content: left !important;
}

/* .apexcharts-pie {
	width: 100% !important; 
} */

#chart {
	max-width: 760px;
	margin: 35px auto;
	opacity: 0.9;
}

#apexcharts-xaxis-label {
	margin-top: 100px;
}

::-webkit-scrollbar {
	width: 8px;
}

/* Handle */
/* set the color of the scrollbar and the radius of its corners */

::-webkit-scrollbar-thumb {
	background: #9ca3af;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
}

/* Handle on hover */
/* set the color of the scrollbar when hovered over */

::-webkit-scrollbar-thumb:hover {
	background: rgb(131, 131, 131);
}

/* Handle on active */
/* set the color of the scrollbar when clicked */

::-webkit-scrollbar-thumb:active {
	background: rgb(104, 104, 104);
}

textarea {
	width: 300px;
	height: 300px;
	border-radius: 25px;
	box-shadow: 0 0 0 3px #000;
	border: 5px solid transparent;
}

.prose.prose p {
	margin-top: 0;
	margin-bottom: 0;
}

.hide-scrollbar {
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
	display: none; /* For Chrome, Safari, and Opera */
}

.show-scrollbar-on-hover:hover {
	scrollbar-width: auto; /* For Firefox */
	-ms-overflow-style: auto; /* For Internet Explorer and Edge */
}

.show-scrollbar-on-hover:hover::-webkit-scrollbar {
	display: block; /* For Chrome, Safari, and Opera */
}
